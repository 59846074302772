import { generateRequest } from '../index';
import {
  addItemToUrl,
  createUrlFromArray,
  formatDateToUtcString,
} from '../../views/Utils';

export const saveUser = async body => {
  const request = await generateRequest();
  const resp = await request.post(`users`, body);
  if (resp.status === 200) {
    return resp.data;
  }
  return {};
};

export const saveUserMultiMerchant = async body => {
  const request = await generateRequest();
  const resp = await request.post(`users/multi-merchant`, body);
  if (resp.status === 200) {
    return resp.data;
  }
  return {};
};

export const saveProfileAccess = async body => {
  const request = await generateRequest();
  const resp = await request.post(`profile/access`, body);
  if (resp.status === 200) {
    return resp.data;
  }
  return {};
};

export const listProfileAccess = async profile => {
  const request = await generateRequest();
  const resp = await request.get(`profile/list/${profile}`);
  if (resp.status === 200) {
    return resp.data;
  }
  return [];
};

export const fetchMyMerchants = async () => {
  const request = generateRequest();
  const resp = await request.get(`/users/me/merchant`);
  if (resp.status === 200) {
    return resp.data;
  }
  return {};
};

export const editUserPassword = async body => {
  const request = generateRequest();
  const resp = await request.put(`/users/me/password`, body);
  if (resp.status === 200) {
    return resp.data;
  }
  return {};
};

export const editUser = async (id, body) => {
  const request = await generateRequest();
  const resp = await request.put(`/users/${id}`, body);
  if (resp.status === 200) {
    return resp.data;
  }
  return {};
};

export const editUserMultiMerchant = async (id, body) => {
  const request = generateRequest();
  const resp = await request.put(`/users/multi-merchant/${id}`, body);
  if (resp.status === 200) {
    return resp.data;
  }
  return {};
};

export const deleteUser = async id => {
  const request = await generateRequest();
  const resp = await request.delete(`/users/${id}`);
  if (resp.status === 200) {
    return resp.data;
  }
  return {};
};

export const getUser = async userId => {
  const request = await generateRequest();
  const resp = await request.get(`users/${userId}`);
  if (resp.status === 200) {
    return resp.data;
  }
  return {};
};

/**
 * @returns {Promise<{id: number, name: string}[]>}
 */
export const getUserMerchantsAccess = async () => {
  const resp = await generateRequest().get(`users/me/merchant`);
  if (resp.status === 200) {
    return resp.data;
  }
  return [];
};

export const listUsers = async storeId => {
  const request = await generateRequest();
  const resp = await request.get(
    `store/users?stores=${storeId ? `[${storeId}]` : '[]'}`
  );
  if (resp.status === 200) {
    return resp.data;
  }
  return [];
};

export const listMerchantProfiles = async () => {
  const request = await generateRequest();
  const resp = await request.get(`merchant/profiles`);
  if (resp.status === 200) {
    return resp.data;
  }
  return [];
};

export const listProfiles = async merchantId => {
  const request = await generateRequest();
  const resp = await request.get(
    `merchant/profiles${merchantId ? `/${merchantId}` : ''}`
  );
  if (resp.status === 200) {
    return resp.data;
  }
  return [];
};

export const listProfilesPdv = async () => {
  const request = await generateRequest();
  const resp = await request.get('accesscontrol/profiles');
  if (resp.status === 200) {
    return resp.data;
  }
  return [];
};

export const saveCustomer = async body => {
  const request = generateRequest();
  const resp = await request.post(`customers`, body);
  if (resp.status === 200) {
    return resp.data;
  }
  return {};
};

export const fetchCustomersColumns = async () => {
  const request = await generateRequest();
  const resp = await request.get(`reports/customers/columns`);
  if (resp.status === 200) {
    return resp.data;
  }
  return [];
};

export const fetchCustomerDetail = async ({
  id,
  stores,
  startDate,
  endDate,
}) => {
  const url = [];
  if (startDate)
    url.push(
      addItemToUrl(
        'initialDate',
        formatDateToUtcString({
          date: startDate,
        })
      )
    );
  if (endDate)
    url.push(
      addItemToUrl(
        'finalDate',
        formatDateToUtcString({
          date: endDate,
          sumDay: true,
        })
      )
    );
  url.push(addItemToUrl('stores', `[${stores}]`));
  const request = await generateRequest();
  const finalUrl = `dashboards/customers/${id}?${createUrlFromArray(url)}`;
  const resp = await request.get(finalUrl);
  if (resp.status === 200) {
    return resp.data;
  }
  return [];
  // http://{{ip}}:{{port}}/api-gerencial/dashboards/customers/140663?stores=[5]&initialDate=2019-05-01T00:01:01.088-03:00&finalDate=2019-05-20T00:01:01.088-03:00
};

export const fetchCustomers = async ({
  startDate,
  endDate,
  stores,
  diffDays,
  initialTicket,
  finalTicket,
  initialTotal,
  finalTotal,
  customerName,
  customerPhone,
  customerCpf,
  product,
  sourceId,
}) => {
  const url = [];
  url.push(addItemToUrl('diffDays', diffDays));
  url.push(addItemToUrl('initialTicket', initialTicket));
  url.push(addItemToUrl('finalTicket', finalTicket));
  url.push(addItemToUrl('initialTotal', initialTotal));
  url.push(addItemToUrl('finalTotal', finalTotal));

  url.push(addItemToUrl('customerName', customerName));
  url.push(addItemToUrl('customerPhone', customerPhone));
  url.push(addItemToUrl('customerCpf', customerCpf));
  url.push(addItemToUrl('productId', product));
  if (startDate) {
    url.push(
      addItemToUrl(
        'initialDate',
        formatDateToUtcString({
          date: startDate,
        })
      )
    );
  }
  if (endDate) {
    url.push(
      addItemToUrl(
        'finalDate',
        formatDateToUtcString({
          date: endDate,
          sumDay: true,
        })
      )
    );
  }
  url.push(addItemToUrl('stores', `[${stores}]`));
  url.push(addItemToUrl('sources', `[${sourceId}]`));
  const request = await generateRequest();
  const finalUrl = `reports/customers?${createUrlFromArray(url)}`;
  const resp = await request.get(finalUrl);
  if (resp.status === 200) {
    return resp.data;
  }
  return [];
};

export const listCustomers = async (search = '') => {
  const request = await generateRequest();

  const resp = await request.get(`callcenter/customers?textSearch=${search}`);
  if (resp.status === 200) {
    return resp.data;
  }
  return [];
};

export const getCustomer = async id => {
  const request = await generateRequest();
  const resp = await request.get(`customers/${id}`);
  if (resp.status === 200) {
    return resp.data;
  }
  return {};
};

export const updateCustomer = async (id, body) => {
  const request = await generateRequest();
  const resp = await request.put(`customers/${id}`, body);
  if (resp.status === 200) {
    return resp.data;
  }
  return {};
};

export const getUserTraining = async url => {
  const request = generateRequest();
  const resp = await request.get(`training?url=${url}`);
  if (resp.status === 200) {
    return resp.data || {};
  }
  return {};
};

export const createOrUpdateUserTraining = async body => {
  const request = generateRequest();
  const resp = await request.post(`training`, body);
  if (resp.status === 200) {
    return resp.data;
  }
  return {};
};
