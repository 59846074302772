import axios from 'axios';
import Storage from '../views/Storage';

const { version } = require('../../package.json');

const token = Storage.getItem('token');

const apiUrl = {
  DEV: 'http://192.168.15.8:8082/',
  DEV2: 'http://localhost:6002/',
  HOMOLOG: 'https://api-gerencial-homolog.opdv.com.br/',
  PRODUCTION: 'https://api-gerencial.opdv.com.br/',
};

export const baseURL = apiUrl[process.env.ENVIRONMENT];

// const baseURL = 'https://api-gerencial.opdv.com.br/';

// const baseURL = 'http://ec2-54-235-113-153.compute-1.amazonaws.com:8081/';

// const baseURL = "http://127.0.0.1:6002/api-gerencial/";

// const baseURL = "http://107.23.71.202:6003/api-gerencial/"

// const baseURL = 'http://localhost:6002/';

const instance = axios.create({
  baseURL,
  timeout: 15 * 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
    'x-access-token': token,
  },
});

export const generateBucketRequest = async (
  contentType = 'application/json'
) => {
  const id = await Storage.getItem('merchantId');
  const headers = {
    'Content-Type': contentType,
  };
  return axios.create({
    baseURL: `https://delivery.opdv.com.br/${id}/`,
    timeout: 15 * 60 * 1000,
    headers,
  });
};

export const generateS3Request = async () => {
  const headers = {
    'Content-Type': 'application/json',
    'X-Api-Key': '3pM5Ova9W3hae7W2cPzC4sTUNbY1VOD2TBbCcAej',
  };
  return axios.create({
    baseURL: `https://neauo6xui1.execute-api.us-east-1.amazonaws.com/default`,
    timeout: 15 * 60 * 1000,
    headers,
  });
};

export const generateRequest = (
  contentType = 'application/json',
  responseType = 'json'
) => {
  const tokenUser = Storage.getItem('token');
  const headers = {
    'Content-Type': contentType,
    'x-access-token': tokenUser,
    'x-version': version,
  };

  const instanceRequest = axios.create({
    baseURL,
    timeout: 15 * 60 * 1000,
    headers,
    responseType, // Define o responseType com base no parâmetro
  });

  instanceRequest.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
      return Promise.reject(error);
    }
  );

  return instanceRequest;
};

export default instance;
